import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/css/swiper.css";
Vue.use(VueAwesomeSwiper);


import requests from '../new-axios.js'   // 记得改为你的路径
Vue.prototype.$http = requests  // 此处命名为$http,你可以改


Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
        document.title = to.meta.title
    }
    next()
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
