import requests from '../../../new-axios.js'

// 首页数据
export function getSubmit(data) {
	return requests({
		url: '/portal/index/validformed',
		method: 'POST',
    params : data
	})
}


