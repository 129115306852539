import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: Index,
    meta: {
    	title: '布网科技—帮助传统企业转型，APP开发，小程序开发，系统定制，运营策划。',
    }
  }
]

const router = new VueRouter({
  mode: 'hash',
  routes
})

export default router
