<template>
  <div>
  <div v-if="pageState">
    <div class="top">
      <div class="top-navigation">
          <div class="navigation-left">
             <img class="company-icon" src="../assets/1.png" alt="">
             <div class="company">
                <p class="company-name">布网科技</p>
                <p class="company-exe">Network technology</p>
             </div>
          </div>
          <!-- <div class="right-choose">
            <img src="../assets/3.png" alt="">
          </div> -->
      </div>
      <div class="propaganda execute-left">
         <img src="../assets/4.png" alt="">
      </div>
      <div class="consultation">
         <img src="../assets/5.png" alt="">
         <span>项目咨询:13333886718</span>
      </div>
      <div class="consultation-button" @click="Makeacall()">
        立即咨询
      </div>
    </div>
    <div class="business">
      <p class="title">主营项目</p>
      <img src="../assets/6.png" alt="">
      <p class="subtitle">帮助传统企业转型</p>
    </div>
    <div class="company-business">
       <div class="bg1">
         <img src="../assets/12.png" alt="">
       </div>
       <div class="bg1 absolute1">
         <img src="../assets/13.png" alt="">
       </div>
       <div class="bg1 absolute2">
         <img src="../assets/14.png" alt="">
       </div>

       <div class="concretehead" ref="testref" v-show="testShow">


       <div class="concrete"  v-for="(item,index) in concreteList" :key="index" >
          <img class="left-top-img" src="../assets/7.png" alt="">
          <div class="company-conent">
            <img :src="item.icon" alt="">
            <span>{{item.title}}</span>
          </div>
          <div class="English">
            {{item.exelist}}
          </div>
          <div class="description">
            {{item.description}}
          </div>
       </div>

       </div>
       <div class="immediately" @click="Makeacall()">
         立即咨询:13333886718
       </div>
    </div>
    <!-- 案例展示 -->
    <div class="case">
       <div class="case-title">
         <p>案例展示</p>
         <img src="../assets/6.png" alt="">
       </div>
      <div class="top-decoration">
        <img src="../assets/15.png" alt="">
      </div>
     <div class="case-swith">
      <swiper class="service-advantage" ref="carouselSwiper" :options="swiperOptions">
      	<swiper-slide v-for="(item,index) in 3" :key="`item${index}`">
      		<div class="card-area">
      			<img class="card-area-img" src="../assets/16.png" alt="">
      		</div>
      	</swiper-slide>
      </swiper>
     </div>
    </div>
   <!-- 我们的优势 -->
   <div class="us-advantage">
     <div class="us-title">
       <p>我们的优势</p>
       <img src="../assets/6.png" alt="">
     </div>
     <div class="range">
       <div class="us-range">
          <p class="p1">百分百原创设计</p>
          <p class="p2">Original design</p>
          <img src="../assets/18.png" alt="">
       </div>
       <div class="us-range">
          <p class="p1">贴心的技术支持</p>
          <p class="p2">technical support</p>
          <img src="../assets/19.png" alt="">
       </div>
     </div>

     <div class="range range-left">
       <div class="us-range us-left">
          <p class="p1">零风险合作</p>
          <p class="p2">Zero risk cooperation</p>
          <img src="../assets/20.png" alt="">
       </div>
       <div class="us-range us-left">
          <p class="p1">重视用户体验</p>
          <p class="p2">User experience</p>
          <img src="../assets/21.png" alt="">
       </div>
     </div>

   </div>
   <!-- 服务流程 -->
   <div class="service">
      <div class="service-title">
        <p>服务流程</p>
        <img src="../assets/6.png" alt="">
      </div>
      <div class="process">
        <div class="process-first left">
           <img class="icon1" src="../assets/24.png" alt="">
           <p class="communication">前期沟通</p>
           <div class="img-txt">
             <img src="../assets/25.png" alt="">
             <span>我们会与您预约时间沟通明确项目的类型、规模、需求、价格，树立合作意向</span>
           </div>
        </div>
        <div class="process-behind process-first left-right">
           <img class="icon2" src="../assets/26.png" alt="">
           <p class="communication2 communication">签订协议</p>
           <div class="img-txt img-txt2">
             <img src="../assets/27.png" alt="">
             <span>项目正式启动，我们会需要您提供项目相关资料包含但不限于企业Logo、企业VI、企业介绍、产品介绍……等图片、文字、视频资料。</span>
           </div>
        </div>
        <div class="process-behind process-first">
            <img class="icon2" src="../assets/26.png" alt="">
            <p class="communication2 communication">页面设计</p>
            <div class="img-txt img-txt2">
              <img src="../assets/27.png" alt="">
              <span>根据需求安排专属设计师与您一对一沟通，无限次修改设计稿直到您满意</span>
            </div>
        </div>
      </div>
   </div>
   <!-- 联系我们 -->
   <div class="contact-us">
      <div class="contact-us-title">
        <p>联系我们</p>
        <img src="../assets/29.png" alt="">
      </div>
      <div class="contact-us-select">
         <div class="select">
            <p class="title">您的需求:</p>
            <div class="select-ui">
              <el-select  @change="selectvalue"  v-model="value" placeholder="请选择您的需求" class="elSelectUi">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
            </div>
         </div>
         <div class="contact-name">
           <p class="title">如何称呼您:</p>
           <input type="text" v-model="name" placeholder="您输入的姓名">
         </div>
         <div class="contact-name">
           <p class="title">如何联系您:</p>
           <input type="number" v-model="phone" placeholder="请输入您的手机号">
         </div>
         <!-- 提交 -->
         <div class="contact-upload" @click="Makeacall()">
            <img src="../assets/30.png" alt="">
         </div>
         <div class="contact-phone">
            <img src="../assets/31.png" alt="">
            <p class="consultation">24小时免费咨询热线</p>
            <p class="phone">13333886718</p>
            <p class="technology">技术支持vx：usethis</p>
         </div>
         <div class="detailed1">
            <span>布网科技(河南)有限公司</span>
            <span>手机号：13333886718</span>
         </div>
         <div class="detailed1">
            <span>地址：洛阳市 洛龙区 东方今典·天汇中心2005</span>
            <span>微信号：usethis</span>
         </div>
         <div class="detailed1">
            <span>邮箱：boss@bigly.cn</span>
            <span>邮编：471000</span>
         </div>
      </div>
   </div>
  </div>
  <div class="Mobile-phone" v-else>
    请使用手机浏览网站
  </div>
  </div>
</template>

<script>
  import { Message } from 'element-ui';
  import {
  	getSubmit
  } from '../api/index/indexApi.js'
  export default {

    data() {
      return {
        options: [{
          value: '企业宣传、营销网站定制',
          label: '企业宣传、营销网站定制'
        }, {
          value: '集团官网高端定制',
          label: '集团官网高端定制'
        },{
          value: '电子商务、交互式平台开发',
          label: '电子商务、交互式平台开发'
        },{
          value: '手机网站、微信二次开',
          label: '手机网站、微信二次开发'
        },{
          value: 'IOS、安卓APP开发',
          label: 'IOS、安卓APP开发'
        },{
          value: '其他',
          label: '其他'
        }],
        value: '',
        swiperOptions: {
        	slidesPerView: 2, //设置slider容器能够同时显示的slides数量,可以是小数,设置为2时,如图所示，设置为3则会出现三张完整的active slide，如API的例子,即设置为偶数时会自动使两边的缩进，类似遮盖一半的效果
        	spaceBetween: 20,
        	centeredSlides: true,//设定为true时，active slide会居中，而不是默认状态下的居左
        	loop: true,
        	slideToClickedSlide: true,//true:点击slide会过渡到这个slide,默认false
        	pagination: {
        		el: 'swiper-pagination',
        		clickable: true
        	}
        },
         concreteList:[{
           id:1,
           icon: require('../assets/8.png'),
           title:'高端网站建设',
           exelist:'DESIGN CUSTOM',
           description:'天天向上从项目策划、架构设计、视觉设计到前后端技术开发，提供全程化、一体化服务模式，拥有国内首屈一指的专业团队。'
         },{
           id:2,
           icon:require('../assets/9.png'),
           title:'电商系统开发',
           exelist:'SHOPPING SYSTEM',
           description:'BWSAAS是自助研发的微信公众号，小程序，APP,H5等多终端SAAS电商系统，具备强大的商城前后台功能和简单易用的购物流程，便捷的用户体验，让网购者感觉到人性化、高效率、安全的网店购买体验。'
         },{
           id:3,
           icon:require('../assets/10.png'),
           title:'移动端定制开发',
           exelist:'MOBILE WEBSITE',
           description:'专注移动端手机网站设计、微网站、手机APP定制开发，创造有活力的品牌网站，在移动互联网市场中取得先机。'
         },{
           id:4,
           icon:require('../assets/11.png'),
           title:'微信小程序开发',
           exelist:'WECHAT SMALL PROGRAM',
           description:'微信小程序是一种新的开放能力，开发者可以快速地开发一个小程序。小程序可以在微信内被便捷地获取和传播，同时具有出色的使用体验。'
         }],
         pageState:false,
         name:'',
         phone:'',
         extra:'',
         requestStatus:false,
         testShow:false
      }
    },
    mounted() {
      //获取打开网站的客户端信息
      if(window.screen.width > 1024){
          this.pageState = false
          // window.location.href = 'http://www.buwangkeji.com'
      }else{
          this.pageState = true
      }
      window.addEventListener('scroll', this.scrollHandle, true)
    },
    beforeDestroy() {
       window.removeEventListener('scroll', this.scrollHandle, true)
    },
    methods:{
      // 元素进入可视化区域  开始执行动画
      scrollHandle() {
        const offset = this.$refs.testref.getBoundingClientRect();
        // 进入可视区域
        if (offset.top <= window.innerHeight && offset.bottom >= 0) {
          // console.log('进入可视区域');
          this.testShow = true
        } else {
          // console.log('移出可视区域');

        }
      },
      Makeacall(){
        window.location.href = "tel://" + 13333886718;
      },
      //提交
      onsubmit(){

          if (this.requestStatus) {
           	// 利用 return 终止函数继续运行
           	return false;
          }
         let obj = {
            extra:this.extra,
            name:this.name,
            phone:this.phone
         }
         getSubmit(obj).then(res=>{
          if(res.data.code == 1){

             }else{
               // Message({
               //   showClose: true,
               //   message: res.data.msg,
               //   type: 'error'
               // });
             }
         })
         Message({
           message: '提交成功',
           type: 'success'
         });

           // 执行函数
           this.requestStatus = true;
           setTimeout(() => {
           	// 模拟执行完毕
           	// 改变 requestStatus
           	this.requestStatus = false;
           }, 1000);
      },
      selectvalue(e){
        this.extra = e
      }
    }
  }
</script>

<style>
  /* 左侧滑入的动画 */
  @keyframes example1 {
    0% {
    transform:translateX(-20%);
    opacity:0;
    }
     100% {
    transform:translateX(0);
    opacity:1;
    }
  }
  /* 右侧滑入动画 */
  @keyframes example2 {
    0% {
    transform:translateX(20%);
    opacity:0;
    }
     100% {
    transform:translateX(0);
    opacity:1;
    }
  }
  /* 执行左侧滑入动画 */
  .execute-left{
    animation: example1 .5s ease-out .5s backwards;
  }
  /* 执行右侧滑入动画 */
  .execute-right{
    animation: example2 .5s ease-out .5s backwards;
  }
  .Mobile-phone{
    margin: auto;
    text-align: center;
    font-size: 18px;
  }
  .detailed1 span{
    font-size: 10px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #888888;
  }
  .detailed1{
    margin: 0 20px;
    width: 350px;
    display: flex;
    justify-content: space-between;
  }
  .contact-phone .technology{
    margin: 8px 0 44px 0;
    font-size: 8px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
  }
  .contact-phone .phone{
    font-size: 20px;
    font-family: DIN-Bold, DIN;
    font-weight: bold;
    color: #FFFFFF;
  }
  .contact-phone .consultation{
    margin: 10px 0 8px 0;
    font-size: 8px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
  }
  .contact-phone img{
    width: 24px;
    height: 24px;
  }
  .contact-phone{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .contact-upload img{
    width: 20px;
    height: 20px;
  }
  .contact-upload{
    margin: 24px 0 54px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #BABCC8;
  }
  .contact-name input{
    padding: 0 0 0 15px;
    outline: none;
    width: 239px;
    height: 38px;
    border: 1px solid #DCDFE6;
    background: none;
    border-radius: 4px;
    color: #fff;
    font-size: 12px;
  }
  .contact-name .title{
    margin: 0 0 8px -10px;
    font-size: 12px;
    font-family: DengXian-Bold, DengXian;
    font-weight: bold;
    color: #FFFFFF;
  }
  .contact-name{
    margin: 36px 0 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .el-input__inner{
    background-color: rgba(0,0,0,0) !important;
  }
  /* .el-select-dropdown{
    background-color: rgba(0,0,0,0) !important;
  } */
  .contact-us-select .select .elSelectUi{
    width: 254px;
    height: 32px;
  }
  /* .contact-us-select .select{
     height: 100px;
  } */
  .contact-us-select .select .title{
     margin: 0 0 8px -10px;
     font-size: 12px;
     font-family: DengXian-Bold, DengXian;
     font-weight: bold;
     color: #FFFFFF;
  }
  .contact-us-select{
    flex-direction: column;
    display: flex;
    align-items: center;
  }
  .contact-us .contact-us-title img{
    margin: -5px 0 0 0;
    width: 290px;
    height: 4px;
  }
  .contact-us .contact-us-title p{
    z-index: 9;
    font-size: 20px;
    font-family: DengXian-Bold, DengXian;
    font-weight: bold;
    color: #fff;
  }
  .contact-us .contact-us-title{
    padding: 40px 0 52px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .contact-us{
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    background-image: url(../assets/28.png);
    width: 375px;
    height: 677px;
  }
  .process-first .img-txt span{
    margin: -4px 0 0 0;
    width: 114px;
    font-size: 8px;
    white-space:normal;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #030C22;
  }
  .process-first .img-txt img{
    width: 4px;
    height: 5px;
    margin:  0 5px 0 0;
  }
  .process-first .img-txt{
    position: absolute;
    top: 70px;
    left: 10px;
    display: flex;
  }
  .img-txt2{
    left: 15px !important;
    top: 57px !important;
  }
  .communication2{
    top: 35px !important;
    left: 21px !important;
  }
  .process-first .communication{
    position: absolute;
    top: 47px;
    left: 16px;
    font-size: 12px;
    font-family: PingFang SC-Bold, PingFang SC;
    font-weight: bold;
    color: #030C22;
  }

  .process-first .icon2{
    position: absolute;
    top: 23px;
    left: 14px;
    width: 9px;
    height: 9px;
  }
  .process-first .icon1{
    position: absolute;
    top: 36px;
    left: 9px;
    width: 9px;
    height: 9px;
  }
  .process .left{
    margin: 0 0 0 16px;
  }
  .process .left-right{
    margin: 0 14px;
  }
  .process-behind{
    width: 148px !important;
    height: 229px !important;
    background-image: url(../assets/23.png) !important;
  }
  .process .process-first{
    position: relative;
    display: inline-block;
    width: 148px;
    height: 242px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    background-image: url(../assets/22.png);
  }
  /* 去除底部滚动条 */
  .process::-webkit-scrollbar {
    display: none;
  }
  .process{
    overflow-x: auto;
    white-space: nowrap;
  }
  .service .service-title img{
    margin: -5px 0 0 0;
    width: 290px;
    height: 4px;
  }
  .service .service-title p{
    z-index: 9;
    font-size: 20px;
    font-family: DengXian-Bold, DengXian;
    font-weight: bold;
    color: #343434;
  }
  .service .service-title{
    padding: 40px 0 44px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .service{
    width: 375px;
    height: 346px;
    background: rgba(255,255,255,0.9);
   /* border: 1px solid #FFFFFF; */
  }
  .us-range img{
    width: 118px;
    height: 116px;
  }
  .us-range .p2{
    margin: 0 0 -25px 0;
    font-size: 10px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #3E628D;
  }
  .us-range .p1{
    font-size: 13px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #3E628D;
  }
  .range-left{
    display: flex;
    justify-content: center;
  }
  .range-left .us-left{
     margin: 0 65px 0 0;
  }
  .us-range{
    margin: 0 0 0 65px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .range{
    display: flex;
    justify-content: flex-end;
  }
  .us-advantage .us-title img{
    margin: -5px 0 0 0;
    width: 290px;
    height: 4px;
  }
  .us-advantage .us-title p{
    z-index: 9;
    font-size: 20px;
    font-family: DengXian-Bold, DengXian;
    font-weight: bold;
    color: #343434;
  }
  .us-advantage .us-title{
    padding: 39px 0 58px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .us-advantage{
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    background-image: url(../assets/17.png);
    width: 375px;
    height: 424px;
  }
  .card-area .card-area-img{
    width: 82px;
    height: 173px;
    margin: auto;
  }
  .case .top-decoration img{
    width: 100%;
    height: 100%;
  }
  .case .top-decoration{
    position: relative;
    z-index: 9;
    width: 179px;
    height: 131px;
    margin: 46px auto -30px auto;
  }
  .swiper-slide {
    background: linear-gradient(125deg, #ECF3FF 0%, rgba(251,251,251,0.44) 47%, rgba(217,232,255,0.4) 100%);
    border: 1px solid #A9CBFF;
  	height: 198px !important;
  	text-align: center;
  	font-size: 18px;
  	/* Center slide text vertically */
  	display: -webkit-box;
  	display: -ms-flexbox;
  	display: -webkit-flex;
  	display: flex;
  	-webkit-box-pack: center;
  	-ms-flex-pack: center;
  	-webkit-justify-content: center;
  	justify-content: center;
  	-webkit-box-align: center;
  	-ms-flex-align: center;
  	-webkit-align-items: center;
  	align-items: center;
  	transition: 300ms;
  	transform: scale(0.8) !important;
  	border-radius: 12px;
  }
  .swiper-slide-active{
     transform: scale(1) !important;
  }
  /* .swiper-slide-duplicate-active {
  	transform: scale(1) !important;
  } */

   /* 去除底部滚动条 */
  .case-swith::-webkit-scrollbar {
    display: none;
  }
  .amamise-swith{
    width: 264px !important;
    height: 198px !important;
    margin: auto 16px auto 16px;
  }
  .case-swith-swith{
    display: inline-block;
    width: 180px;
    height: 135px;
    background: linear-gradient(125deg, #ECF3FF 0%, rgba(251,251,251,0.44) 47%, rgba(217,232,255,0.4) 100%);
    border-radius: 10px 10px 10px 10px;
    border: 1px solid #A9CBFF;
  }
  .case-swith{
    white-space: nowrap;
    overflow-x: auto;

  }
  .medium{
    width: 264px;
    height: 198px;
    background: red;
  }
  .case .case-title img{
    margin: -5px 0 0 0;
    width: 290px;
    height: 4px;
  }
  .case .case-title p{
    z-index: 9;
    font-size: 20px;
    font-family: DengXian-Bold, DengXian;
    font-weight: bold;
    color: #343434;
  }
  .case .case-title{
    padding: 72px 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .case{
    width: 375px;
    height: 485px;
    background: linear-gradient(180deg, #FFFFFF 0%, #DFF2FC 100%);
  }
  .company-business .immediately{
    margin: 36px 0 46px 0;
    width: 204px;
    height: 42px;
    border-radius: 21px 21px 21px 21px;
    opacity: 1;
    border: 1px solid;
    font-size: 14px;
    font-family: PingFang SC-Bold, PingFang SC;
    font-weight: bold;
    color: #336EFA;
    line-height: 42px;
    text-align: center;
   /* border-image: linear-gradient(355deg, rgba(51, 110, 250, 1), rgba(88, 218, 252, 0.2)) 1 1; */
  }
  .absolute2{
    top: 746px !important;
  }
  .absolute1{
    top: 342px !important;
  }
  .company-business .bg1 img{
    width: 100%;
    height: 100%;
  }
  .company-business .bg1{
    position: absolute;
    top: -130px;
    width: 375px;
    height: 272px;
  }
  .concrete .description{
    padding: 0 12px;
    text-align: center;
    font-size: 13px;
    font-family: DengXian-Regular, DengXian;
    font-weight: 400;
    color: #343434;
  }
  .concrete .English{
    margin: 10px 0 14px 0;
    text-align: center;
    font-size: 12px;
    font-family: DengXian-Regular, DengXian;
    font-weight: 400;
    color: #343434;
  }
  .company-conent span{
    width: 118px;
    height: 32px;
    background: #3C88FA;
    box-shadow: inset 0px 0px 20px 0px #FFFFFF;
    border-radius: 40px 14px 14px 0;
    font-size: 14px;
    font-family: DengXian-Bold, DengXian;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 32px;
    text-align: center;
    margin: 30px 0 0 0;
  }
  .company-conent img{
    width: 68px;
    height: 68px;
  }
  .company-conent{
    display: flex;
    margin: 32px 0 0 0;
    justify-content: center;
  }
  .left-top-img{
    position: absolute;
    top: 0;
    left: 0;
    width: 80px;
    height: 91px;
  }

  .company-business .concrete{

    background: #FFFFFF;
    width: 295px;
    height: 220px;
    overflow: hidden;
    position: relative;
    box-shadow: 4px 6px 10px 0px rgba(51,110,250,0.15), -2px 0px 6px 0px rgba(51,110,250,0.15);
    border-radius: 10px 10px 10px 10px;
    margin: 0 0 24px 0;
  }
  .company-business{
    margin: 46px 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }


  .concrete:nth-child(1){
    animation: example1 .5s ease-out .5s backwards;
  }
  .concrete:nth-child(2){
    animation: example2 .5s ease-out 1.5s backwards;
  }
  .concrete:nth-child(3){
    animation: example1 .5s ease-out 2.5s backwards;
  }
  .concrete:nth-child(4){
    animation: example2 .5s ease-out 3.5s backwards;
  }


  .business .subtitle{
    font-size: 14px;
    font-family: DengXian-Bold, DengXian;
    font-weight: bold;
    color: #343434;
  }
  .business img{
    margin: -5px 0 12px 0;
    width: 290px;
    height: 4px;
  }
  .business{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .business .title{
    z-index: 9;
    margin: 36px 0 0 0;
    font-size: 20px;
    font-family: DengXian-Bold, DengXian;
    font-weight: bold;
    color: #343434;
  }
  .consultation-button{
    width: 161px;
    height: 42px;
    background: linear-gradient(140deg, #336EFA 0%, #64FBFD 100%);
    border-radius: 21px 21px 21px 21px;
    font-size: 14px;
    font-family: PingFang SC-Bold, PingFang SC;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 42px;
    text-align: center;
    margin: 34px auto 0 auto;
  }
  .consultation span{
    font-size: 14px;
    font-family: PingFang SC-Bold, PingFang SC;
    font-weight: bold;
    color: #FFFFFF;
  }
  .consultation img{
    width: 20px;
    height: 20px;
    margin-right: 6px;
  }
  .consultation{
    display: flex;
    justify-content: center;
  }
  .propaganda img{
    width: 100%;
    height: 100%;
  }
  .propaganda{
    margin: 117px auto 57px auto;
    width: 292px;
    height: 190px;
  }
  .right-choose img{
    width: 100%;
    height: 100%;
  }
  .right-choose{
    width: 24px;
    height: 24px;
    margin: auto 20px auto 0;
  }
  .company-name{
    font-size: 15px;
    font-family: YouSheBiaoTiHei-Regular, YouSheBiaoTiHei;
    font-weight: 400;
    color: #FFFFFF;
  }
  .company-exe{
    font-size: 10px;
    font-family: SimHei-Regular, SimHei;
    font-weight: 400;
    color: #FFFFFF;
  }
  .company{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .company-icon{
    margin-right: 8px;
    width: 36px;
    height: 36px;
  }
  .navigation-left{
    float: left;
    display: flex;
    align-items: center;
    margin: 0 0 0 20px;
  }
  .top{
    width: 375px;
    height: 540px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    background-image: url(../assets/2.png);
  }
 .top-navigation{
   display: flex;
   justify-content: space-between;
   width: 375px;
   height: 48px;
   background: rgba(114,114,114,0.36);
 }
 *{
   padding: 0;
   margin: 0;
 }
</style>
